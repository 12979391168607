<template>
  <b-card class="mb-0">
    <div>
      <b-row class="align-items-center">
        <b-col cols="12" lg="6" md="6" sm="12" xs="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
        <b-col cols="12" lg="12" md="12" sm="12" xs="12">
          <div class="d-flex align-items-center justify-content-between mb-1">
            <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
              <v-select :options="options" label="pagination" v-model="perPage" />
            </b-col>
            <div class="d-flex align-items-center justify-content-end">
              <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
                <b-form-input class="" v-model="search" type="search" placeholder="Search" />
              </b-col>
              <b-button v-if="currentUser.roles[0].name !== 'HEAD_OF_DEPARTMENT'" variant="outline-primary"
                @click="$router.push('/users/create')">
                + User
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>



      <b-table id="userTable" responsive show-empty :items="filteredUsers" :key="filteredUsers.id" :fields="fields"
        :per-page="perPage" :current-page="currentPage">
        <!-- Role -->
        <template #cell(role)="data">
          <b-badge :variant="`light-${rolesStatusVariant(data.item.roles[0].name)}`">
            {{ rolesStatusText(data.item.roles[0].name) }}
          </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="row">
          <b-row>
            <b-col cols="2">
              <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm" @click="putRedirect(row)">
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-button class="p-0" v-b-tooltip.hover title="Disable" variant="flat" size="sm" @click="clickModal(row)">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-button class="p-0" v-if="!row.item.email_verified_at" v-b-tooltip.hover title="Resend" variant="flat" size="sm"
                @click="resendEmail(row.item)">
                <feather-icon icon="MailIcon" />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>

      <!-- Pagination -->
      <b-row align-h="end">
        <b-col md="12" class="d-flex align-items-center justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="userTable"></b-pagination>
        </b-col>
      </b-row>


    </div>
  </b-card>
</template>

<script>
import {
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BCollapse,
  BForm,
  BContainer,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge,
  BFormInput,
  VBTooltip
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'

export default {
  components: {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BPagination,
    BRow,
    BCol,
    vSelect,
    BModal,
    BCollapse,
    BForm,
    BContainer,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormInput,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "role", label: "Role" },
        { key: "actions", label: "Actions" },
      ],
      options: [
        10, 50, 100
      ],
      search: "",
      currentPage: 1,
      perPage: 10
    };
  },

  async created() {
    await this.refetchData();
  },

  computed: {
    currentUser() {
      return this.$store.state.usersConfig.currentUser;
    },
    users() {
      return this.currentUser.roles[0].name === "HEAD_OF_DEPARTMENT"
        ? this.$store.getters.departmentUsers(this.currentUser.department) :
        this.$store.state.usersConfig.users;
    },
    filteredUsers() {
      return this.users.filter((user) => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase()) ||
          user.roles[0].name.toLowerCase().includes(this.search.toLowerCase())
        );
      })
        .filter(user => user.status === 1 && user.id !== this.currentUser.id);
    },
    rows() {
      return this.filteredUsers.length

    }
  },

  methods: {
    async resendEmail(item) {
      try {
        await this.$store.dispatch('resendEmail', { email: item.email });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Email Sent",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error sending email",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    async refetchData() {
      await this.$store.dispatch("getAllUsers");
    },
    putRedirect(row) {
      this.$router.push("/users/" + row.item.id + "/update");
    },
    async disableUser(item) {
      const data = Object.assign(item, {
        status: 0,
        fk_role: item.roles[0].id,
      });
      this.$store.dispatch("updateUser", data);
      await this.refetchData();
    },
    clickModal(row) {
      this.row = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to disable that user.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value == true) {
            await this.disableUser(row.item);
          }
        });
    },
    rolesStatusVariant(role) {
      const roleVariants = {
        SUPERADMIN: "primary",
        EMPLOYEE: "warning",
        PROJECT_MANAGER: "danger",
        HEAD_OF_DEPARTMENT: "info",
        HEAD_OF_ORGANIZATION: "success",
      };
      return roleVariants[role] || "light";
    },

    rolesStatusText(role) {
      const roleTexts = {
        SUPERADMIN: "SUPER ADMIN",
        EMPLOYEE: "EMPLOYEE",
        PROJECT_MANAGER: "PROJECT MANAGER",
        HEAD_OF_DEPARTMENT: "HEAD OF DEPARTMENT",
        HEAD_OF_ORGANIZATION: "HEAD OF ORGANIZATION",
      };
      return roleTexts[role] || "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
/* Custom CSS to right-align search input and "+ User" button */
.b-row {
  display: flex;
  justify-content: flex-end;
}

.b-form-input {
  margin-left: auto;
}
</style>